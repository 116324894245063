<template>

  <div class="row" ref="newsForm">
    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <ValidationProvider
                  vid="title"
                  rules="required"
                  name="The Title"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Title"
                            name="title"
                            fou
                            @keyup="generateSomeFields"
                            v-model="formData.title">
                  </fg-input>
                </ValidationProvider>
                <ValidationProvider
                  vid="link"
                  rules=""
                  name="The Link"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="Link"
                            name="link"
                            fou
                            v-model="formData.link">
                  </fg-input>
                </ValidationProvider>

                <ValidationProvider
                  vid="external_link"
                  rules=""
                  name="The External Link"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :error="failed ? errors[0]: null"
                            label="External Link"
                            name="external_link"
                            fou
                            v-model="formData.external_link">
                  </fg-input>
                </ValidationProvider>
                <div class="form-group">
                    <label>Description</label>
                    <ValidationProvider
                      vid="description"
                      rules="required"
                      name="The Description"
                      v-slot="{ passed, failed,errors }">
                        <editor
                          v-model="formData.description"
                          :api-key="editorKey"
                          :init='editorConfig'
                          
                          :error="failed ? errors[0]: null"
                          :key="'description'"
                        />
                  </ValidationProvider>          
                </div>

                <div class="form-group">
                    <label>Brief</label>
                    <ValidationProvider
                      vid="brief"
                      rules=""
                      name="The Brief"
                      v-slot="{ passed, failed,errors }">
                        <editor
                          v-model="formData.brief"
                          :api-key="editorKey"
                          :init='editorConfig'
                          :error="failed ? errors[0]: null"
                          :key="'brief'"
                        />
                  </ValidationProvider>          
                </div>

                <div class="form-group">
                  <label>Image</label>
                  <el-tooltip placement="right">
                    <div slot="content">(290 × 390)</div>
                    <span style="margin: 0 5px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                             class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
                  </el-tooltip>
                  <prime-uploader
                    :preview-width="'200px'"
                    :preview-height="'200px'"
                    :elementNum="1"
                    v-model="formData.image"
                  >
                  </prime-uploader>
                </div>

                <div>
                  <label class="card-label">News Date</label>
                    <fg-input v-model="formData.news_date"
                              value-format="YYYY-MM-DD"
                              type="date"
                              placeholder="Chose news date"
                    >
                    </fg-input>
                </div>

              </div>
            </div>
            <div class="form-group pt-4">
              <label>Is Active</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>
          </div>
          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/news/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, TableColumn, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import GeneralDataTable from "@/components/GeneralDataTable";
import draggable from 'vuedraggable';

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    [TableColumn.name]: TableColumn,
    FgSelect,
    LSwitch,
    GeneralDataTable,
    PrimeUploader,
    'editor': Editor,
    draggable
  },
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      formData: {
        title: "",
        description: "",
        brief: "",
        external_link: "",
        news_date: "",
        link: "",
        image: "",
        is_active: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    }),
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.newsForm
    });

    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit news";
      this.getnews();
    } else {
      this.editMode = false;
      this.formTitle = "Add news";
      this.loader.hide();
    }
  },


  methods: {
    getnews() {
      let data = {
        id: this.id,
      }
      this.axios.post("news/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "news Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      var numChars = this.formData.brief? this.formData.brief.replace( /(<([^>]+)>)/ig, '').length: 0;
      if (numChars > 100) {
        alert("Maximum 100 characters allowed for brief.");
        this.submitting = false;
        return false;
      }

      if (this.editMode === true) {
        request = this.axios.put("news/update/" + this.id, this.formData);
        successMessage = "news updated successfully";
      } else {
        request = this.axios.post("news/create", this.formData);
        successMessage = "news added successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/news/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    },
    generateSomeFields() {
      
      // if(this.editMode){
      //     return;
      //   }
      let newUrl = '';
      if (this.formData.title) {
        newUrl = this.formData.title.replace(/\s+/g, "_").toLowerCase();
        newUrl = newUrl.replace(/[^ءأ-يa-zA-Z0-9-]/g, "_");
        newUrl = newUrl.replace(/([-]+)/g, "_");
      }

      this.formData.link = newUrl;
    }
  }
}
</script>

<style>

.extra-fields {
  box-shadow: rgb(235 232 232) 1px 1px 20px 0px, rgb(235 232 232) -1px -1px 20px 0px;
  padding: 0 0 3px;
}

.extra-fields > h4 {
  padding: 20px;
}
</style>
